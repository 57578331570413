import React, { useEffect } from 'react';
import styles from './ContactPage.module.css';
import ContactRow from '../../components/Contacts/ContactRow';
import ContactCard from '../../components/Contacts/ContactCard';
import { project, estimating, executive } from './ContactList';

import Aos from 'aos';
import 'aos/dist/aos.css';

const ContactPage = () => {
  useEffect(() => {
    Aos.init({ duration: 1000, once: 'true' }, []);
  });

  return (
    <section className={styles.contact_page}>
      <section className={styles.contact_hero}>
        <div className={styles.contact_body}>
          <h1
            className={styles.contact_header}
            data-aos="fade-right"
            data-aos-delay="100"
          >
            Get in touch
          </h1>
          <p
            className={styles.contact_subheader}
            data-aos="fade-right"
            data-aos-delay="1300"
          >
            For any visual display boards, Tack wall panels or Tables & Benches
            <br />
            You can call us at{' '}
            <span className={styles.contact_info}>909-383-0819</span>{' '}
          </p>

          <div
            className={styles.contact_address_box}
            data-aos="fade-right"
            data-aos-delay="1300"
          >
            <p className={styles.contact_subheader}>
              We are located in{' '}
              <span className={styles.contact_info}>
                420 South E St San Bernardino CA, 92401
              </span>
            </p>
          </div>
        </div>
      </section>

      <section className={styles.contact_team}>
        <div data-aos="fade-up" data-aos-delay="100">
          <ContactRow rowTitle={'Project Management Team'}>
            {project.map((proj) => (
              <ContactCard
                key={proj.id}
                name={proj.name}
                title={proj.title}
                email={proj.email}
                extNumber={proj.ext}
              />
            ))}
          </ContactRow>
        </div>

        {/* Estimating Team */}
        <div data-aos="fade-up" data-aos-delay="100">
          <ContactRow rowTitle={'Estimating Team'}>
            {estimating.map((est) => (
              <ContactCard
                key={est.id}
                name={est.name}
                title={est.title}
                email={est.email}
                extNumber={est.ext}
              />
            ))}
          </ContactRow>
        </div>

        {/* Senior Manager */}
        <div data-aos="fade-up" data-aos-delay="100">
          <ContactRow rowTitle={'Senior Manager'}>
            <ContactCard
              name={'Albert Cardenas'}
              title={'Senior Manager'}
              email={'albert@nelsonadamsnaco.com'}
              extNumber={'Ext. 113'}
            />
          </ContactRow>
        </div>

        {/* Legal Department */}
        <div data-aos="fade-up" data-aos-delay="100">
          <ContactRow rowTitle={'Legal Department'}>
            <ContactCard
              name={'Kelvin Chavez'}
              title={'Contracts'}
              email={'kelvin@nelsonadamsnaco.com'}
              extNumber={'Ext. 106'}
            />
          </ContactRow>
        </div>

        {/* Executive Team */}
        <div data-aos="fade-up" data-aos-delay="100">
          <ContactRow rowTitle={'Executive Team'}>
            {executive.map((exec) => (
              <ContactCard
                key={exec.id}
                name={exec.name}
                title={exec.title}
                email={exec.email}
                extNumber={exec.ext}
              />
            ))}
          </ContactRow>
        </div>
      </section>
    </section>
  );
};

export default ContactPage;
