import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './DisplayCasesPage.module.css';

import SliderContent from '../../../components/ProductsSlider/SliderContent';

import hdc from '../../../assets/images/DisplayCases/HingedDisplayCases/hdc_main.png';
import sdc from '../../../assets/images/DisplayCases/SlidingDisplayCase/sdc_main.png';

const DisplayCasesPage = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 640;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  const displayCases = [
    {
      id: 1,
      product: 'Hinged Display Case',
      imageSrc: hdc,
      category: 'display-cases',
      item: 'hinged-display-case',
    },
    {
      id: 2,
      product: 'Vertical Sliders',
      imageSrc: sdc,
      category: 'display-cases',
      item: 'sliding-display-case',
    },
  ];

  return (
    <>
      {width <= breakpoint ? (
        <SliderContent
          header="Display Cases"
          products={displayCases}
          slidesNum={1}
        />
      ) : (
        <section className={styles.section_display}>
          <h1 className={styles.dc_header}>Display Cases</h1>
          <div className={styles.dc_box}>
            <div className={styles.dc_item}>
              <img src={hdc} alt="hinged_display_cases" />
              <Link
                to="/display-cases/hinged-display-case"
                className={styles.dc_title}
              >
                Hinged Display Case
              </Link>
            </div>

            {/* Item 2 */}
            <div className={styles.dc_item}>
              <img src={sdc} alt="sliding_display_cases" />
              <Link
                to="/display-cases/sliding-display-case"
                className={styles.dc_title}
              >
                Sliding Display Case
              </Link>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default DisplayCasesPage;
